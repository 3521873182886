<template>
  <moe-page title="主图打标">
    <moe-card class="mb-20" :autoHeight="true">
      <template slot="header">
        <div class="df aic jcsb">
          <div class="df fdc">
            <div class="font-20 fwb">主图打标</div>
          </div>
        </div>
      </template>

      <!-- 筛选信息 -->
      <moe-inquire @search="goodsBadgeSearch">
        <el-form-item label="状态">
          <moe-select type="goodsBadgeStateList" v-model="goodsBadgeParams.state" placeholder="选择状态"></moe-select>
        </el-form-item>
        <el-form-item label="活动名称">
          <el-input v-model.trim="goodsBadgeParams.name" placeholder="输入活动名称" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="活动编号">
          <el-input :value="goodsBadgeParams.id" @input="(value) => goodsBadgeParams.id = $moe_formatter.formatterId(value)" placeholder="输入活动编号" maxlength="50" clearable />
        </el-form-item>
        <el-form-item label="使用时间" prop="startTime">
          <el-date-picker
            style="width: 100%;"
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([goodsBadgeParams.startTime, goodsBadgeParams.endTime] = datetime || ['', ''])" />
        </el-form-item>
      </moe-inquire>

      <moe-table ref="goodsBadgeTableRef" url="/shop/goodsBadge/list" :params="goodsBadgeParams" :numberShow="false">
        <el-button slot="tool" type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/activityManage/goodsBadge/add')">新建活动</el-button>

        <el-table-column label="活动信息" min-width="150">
          <template slot-scope="{ row }">
            <div>{{ row.name }}</div>
            <div>编号:{{ row.id }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="cover" label="活动图标" width="180">
          <template slot-scope="{ row }">
            <moe-image :src="row.cover" width="100px" height="100px" />
          </template>
        </el-table-column>
        <el-table-column label="使用时间" min-width="310">
          <div class="df fdc aic jcc" slot-scope="{ row }">
            <div>{{ $moe_time.getTimeStamp(row.startTime) }} 至 {{ $moe_time.getTimeStamp(row.endTime) }}</div>
            <div>活动持续：{{ $moe_time.countDown(row.endTime, row.startTime) }}</div>
          </div>
        </el-table-column>

        <el-table-column label="状态" min-width="150">
          <div :class="$moe_format.getGoodsBadgeStateListColor(row.state)" slot-scope="{ row }">
            {{ $moe_format.getGoodsBadgeStateList(row.state) }}
          </div>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="450">
          <div class="moe-table_btns" slot-scope="{ row }">
            <el-button type="success" size="mini" icon="el-icon-document" @click="$moe_coordinator.navigateTo(`/activityManage/goodsBadge/detail`, { id: row.id })">查看活动</el-button>
            <el-button v-if="!['FINISHED'].includes(row.state)" type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/activityManage/goodsBadge/add`, { id: row.id })">编辑</el-button>
            <el-button v-if="['IN_PROGRESS'].includes(row.state)" type="danger" size="mini" icon="el-icon-circle-close" @click="handleEnd(row)">结束</el-button>
            <el-button v-if="['NOT_STARTED'].includes(row.state)" type="danger" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
          </div>
        </el-table-column>
      </moe-table>
    </moe-card>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageGoodsBadgeList',
  data() {
    return {
      goodsBadgeParams: {
        pageNum: 1,
        pageSize: 10,
        id: '',
        name: '',
        state: '',
        startTime: '',
        endTime: '',
      },
      datetime: [],
    }
  },
  methods: {
    goodsBadgeSearch(isSearch) {
      if (!isSearch) {
        this.goodsBadgeParams = {
          pageNum: 1,
          pageSize: 10,
          id: '',
          name: '',
          state: '',
          startTime: '',
          endTime: '',
        }

        this.datetime = [];
      }

      this.$refs['goodsBadgeTableRef'].searchData();
    },
    async handleDelete({ name, id }) {
      this.$moe_layer.confirm(`您确定要删除当前选项"${name}"吗？`, async () => {
        const { code, message } = await this.$moe_api.GOODSBADGE_API.delGoodsBadge({ id })
        if (code === 200) {
          this.$moe_msg.success('删除成功');
          this.goodsBadgeSearch(false);
        } else {
          this.$moe_msg.error(message);
        }
      })
    },
    async handleEnd({ name, id }) {
      this.$moe_layer.confirm(`您确定要结束当前选项"${name}"吗？`, async () => {
        const { code, message } = await this.$moe_api.GOODSBADGE_API.closeGoodsBadge({ id })
        if (code === 200) {
          this.$moe_msg.success('结束成功');
          this.goodsBadgeSearch(false);
        } else {
          this.$moe_msg.error(message);
        }
      })
    },
  },
  mounted() { }
}
</script>

<style lang="scss"></style>